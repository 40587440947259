<script setup>
 import {
  testLinkScheme,
  testInternalLink,
  testRelativeLink,
  formatUrl,
  checkActive,
  testValidDomains,
 } from '@/composables/helpers/links';

 const props = defineProps({
  href: {
   type: String,
   default: '',
   required: false,
  },
  replace: {
   type: Boolean,
   default: false,
  },
  target: {
   type: [String, Boolean],
   default: false,
  },
  windowed: {
   type: Boolean,
   default: false,
  },
  forceInternalLink: {
   type: Boolean,
   default: false,
  },
  forceExternalLink: {
   type: Boolean,
   default: false,
  },
 });

 const { href, replace, target, windowed } = toRefs(props);

 // Variables
 const internal = ref(false);
 const relative = ref(false);

 const url = ref(href.value);
 const validDomain = ref(false);
 const validScheme = ref(false);

 const route = useRoute();
 const active = ref(false);

 // Methods
 const setUp = () => {
  // Update the URL
  url.value = href.value;

  // Check if the link has a valid scheme, if so, we shouldn't open in a new tab
  validScheme.value = testLinkScheme(url.value);
  validDomain.value = testValidDomains(url.value);

  // If the link is relative, it's internal
  // Else we test if it's a valid URL, and then check if it's host is internal
  relative.value = testRelativeLink(url.value);
  if (relative.value) internal.value = true;
  else internal.value = testInternalLink(url.value);

  // If the link is internal, we format it
  if (internal.value) url.value = formatUrl(url.value, relative.value);
 };

 const replaceState = () => {
  history.replaceState({}, null, url.value);
 };

 const updateActive = () => {
  if (internal.value) active.value = checkActive(url.value, route.fullPath);
 };

 const openWindow = () => {
  window.open(url.value, '_blank', 'width=500,height=500');
 };

 const setActiveClass = () => {
  if (active.value == 1) return 'mess-active-link';
  if (active.value == 2) return 'mess-current-active-link';
  return '';
 };

 // Lifecycle
 setUp();
 updateActive();

 watch(route, () => {
  updateActive();
 });

 watch(href, () => {
  setUp();
  updateActive();
 });

 useNuxtApp().hook('page:transition:finish', () => {
  updateActive();
 });
 useNuxtApp().hook('page:finish', () => {
  updateActive();
 });

 const forceRefresh = computed(() => {
  // If the link is on the current page, and has parameters we should replace the state
  let hit = false;

  const onCurrentPage = url.value.includes(route.path);
  // If we are on the current page, and the link has a query, we should force a refresh
  // EG: route.path = /agenda, url.value = /agenda?day=1 -> force refresh
  // EG: route.path = /agenda, url.value = /agenda -> no refresh
  if (onCurrentPage) hit = url.value.includes('?');
  // If we are on the current page, have params, and the link is the root page, we should force a refresh
  // EG: route.path = /agenda, url.value = / -> no refresh
  // EG: route.path = /agenda?day=1, url.value = /agenda -> force refresh
  if (onCurrentPage && !hit) {
   const hasParams = route.fullPath.includes('?');
   const isRootPage = route.path === url.value;
   hit = hasParams && isRootPage;
  }
  return hit;
 });

 //  $bus.on('404-page', () => {
 //   is404.value = true;
 //   console.log(`🍤 ~ $bus.on ~ url404.value:`, url404.value);
 //  });

 //  const is404Page = computed(() => {
 //   if (is404.value) {
 //    url404.value = url.value;
 //    return true;
 //   }
 //   return false;
 //  });

 const isIframe = internal.value && route.path.startsWith('/iframe') && !route.path.includes('/iframe/search');
 const iFrameUrl = url.value.startsWith('/iframe') ? url.value : `/iframe${url.value}`;
</script>

<template>
 <!-- <a v-if="is404Page" :href="url404" :class="`mess-link force-refresh internal-link ${setActiveClass()}`">
  <slot />
 </a> -->
 <a v-if="replace" :href="url" class="mess-link replace" @click.prevent="replaceState()">
  <slot />
 </a>
 <a v-else-if="isIframe" :href="iFrameUrl" target="iframe"><slot /></a>
 <a
  v-else-if="forceRefresh && internal"
  :href="url"
  :class="`mess-link force-refresh internal-link ${setActiveClass()}`"
 >
  <slot />
 </a>
 <a v-else-if="windowed" class="mess-link window" :href="url" @click.prevent="openWindow()">
  <slot />
 </a>
 <NuxtLink :to="url" v-else-if="internal || forceInternalLink" :class="`mess-link internal-link ${setActiveClass()}`">
  <slot />
 </NuxtLink>
 <span v-else-if="!validScheme && !validDomain">
  <slot />
 </span>

 <a
  :href="'https://' + url"
  :target="target ? target : '_blank'"
  v-else-if="!validScheme"
  class="mess-link external-link"
 >
  <slot />
 </a>

 <a :href="url" :target="target ? target : '_blank'" v-else class="mess-link external-link">
  <slot />
 </a>
</template>
import type { getEnv } from '~/composables/helpers/getEnv';
